import React from "react";

import Layout from "../components/Layout/Layout";
import Resume from "../components/Resume/Resume";

export default function ResumePage() {
  return (
    <Layout>
      <Resume />
    </Layout>
  );
}
