import React from "react";
import { navigate } from "gatsby";
import { gsap } from "gsap";

export default function Resume({ children }) {
  React.useEffect(() => {
    const invertColors = () => {
      const root = document.documentElement;
      const styles = window.getComputedStyle(root);

      const color1 = styles.getPropertyValue("--dark");
      const color2 = styles.getPropertyValue("--light");

      root.style.setProperty("--dark", color2);
      root.style.setProperty("--light", color1);
    };

    invertColors();

    return invertColors;
  }, []);

  return (
    <div className="resume">
      <div className="resume-header">
        <div className="text xl center lrsp5">Vladyslav Stupaiev</div>
        <div className="text lg center opacity9">
          Software Development Engineer
        </div>
        <div className="text md center opacity7">Dnipro, Ukraine</div>
        <div className="resume-header-contacts flex x-between">
          <div className="text right opacity9">+380988589471</div>
          <div className="text right opacity9">log69x@gmail.com</div>
        </div>
      </div>

      <div className="separator" />

      <div className="resume-section">
        <div className="text lg center w600 ma20">Summary</div>
        <p className="text justify indent3 opacity9">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor,
          deserunt ducimus. Eveniet suscipit fuga consectetur cumque quasi
          molestiae enim delectus commodi officiis necessitatibus alias placeat,
          sed sit rem, esse sapiente?
        </p>
      </div>

      <div className="resume-section">
        <div className="text lg center w600 ma20">Employment History</div>

        <div className="ma20">
          <div className="project-dates my20">
            <div className="text md">Project 1</div>
            <div className="text">May 2018 - Sep 2019</div>
          </div>
          <p className="text justify indent3 opacity9">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor,
            deserunt ducimus. Eveniet suscipit fuga consectetur cumque quasi
            molestiae enim delectus commodi officiis necessitatibus alias
            placeat, sed sit rem, esse sapiente?
          </p>
        </div>

        <div className="ma20">
          <div className="project-dates my20">
            <div className="text md">Project 2</div>
            <div className="text">May 2018 - Sep 2019</div>
          </div>
          <p className="text justify indent3 opacity9">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor,
            deserunt ducimus. Eveniet suscipit fuga consectetur cumque quasi
            molestiae enim delectus commodi officiis necessitatibus alias
            placeat, sed sit rem, esse sapiente?
          </p>
        </div>
      </div>

      <div className="resume-section">
        <div className="text lg center w600 ma20">Education</div>
        <div className="project-dates my20">
          <div className="text md">Oles Honshan DNU</div>
          <div className="text">May 2016 - Sep 2020</div>
        </div>
        <div className="project-dates my20">
          <div className="text md">Oles Honshan EOM</div>
          <div className="text">May 2020 - Sep 2022</div>
        </div>
      </div>

      <div className="resume-section">
        <div className="text lg center w600 ma20">Additional Education</div>
        <div className="project-dates my20">
          <div className="text md">Oles Honshan DNU</div>
          <div className="text">May 2016 - Sep 2020</div>
        </div>
        <div className="project-dates my20">
          <div className="text md">Oles Honshan EOM</div>
          <div className="text">May 2020 - Sep 2022</div>
        </div>
      </div>
    </div>
  );
}
